
import Vue from "vue";

export default Vue.extend({
  name: "EditableLabel",
  props: {
    label: String,
    text: String,
    textClass: String,
    disabled: Boolean,
    labelClass: String,
    defaultText: String,
  },
  data() {
    return {
      editing: false,
      updatedText: this.text,
    };
  },
  methods: {
    startEditing() {
      if (this.disabled) return
      
      this.editing = true;
      setTimeout(() => {
        (this.$refs as any).editableField.focus();
      });
    },
    onInput(val: any) {
      this.updatedText = val;
    },
    finishEditing() {
      this.$emit("update", this.updatedText);
      this.editing = false;
    },
  },
  watch: {
    disabled(val) {
      if (val) {
        this.editing = false;
      }
    },
  },
});
