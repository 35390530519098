
import { associates, AssociateStatus, IAssociate } from "@/api";
import UserIcon from "@/components/common/UserIcon.vue";
import { permissions } from "@/mixins";
import { Actions } from "@/store/models";
import { bufferToImage } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  components: { UserIcon },
  name: "UserCard",
  mixins: [permissions],
  props: {
    user: Object as () => IAssociate,
  },
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    isActive(): boolean {
      return this.user.status === AssociateStatus.Active;
    },
    viewable(): boolean {
      return (
        (this as any).hasUsersPermission ||
        this.user.id === this.$store.state.associate?.id
      );
    },
  },
  methods: {
    async updateStatus() {
      try {
        // todo: api returns unauthorised user action
        await associates.updateStatus(
          this.user.id,
          this.isActive ? AssociateStatus.Deactivated : AssociateStatus.Active
        );
        await this.$store.dispatch(Actions.GetCompanyAssociates);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    // removes user from group
    async deleteUser(groupId: number) {
      try {
        const associate_groups: number[] = [];
        this.user.associate_associates_groups.forEach((g) => {
          if (g.associates_group.id !== groupId) {
            associate_groups.push(g.associates_group.id);
          }
        });
        await associates.update(this.user.id, {
          associate_groups,
        });
        await this.$store.dispatch(Actions.GetCompanyAssociates);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    view() {
      if (this.viewable) {
        this.$router.push(this.$route.path + `/${this.user.id}`);
      }
    },
  },
  watch: {
    selected() {
      this.$emit("selectionUpdated");
    },
  },
});
