
import { associates, IAssociate } from "@/api";
import { Actions } from "@/store/models";
import { getDate, getYear } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "AddMemberModal",
  props: {
    dialog: Boolean,
    groupName: String,
  },
  data() {
    return {
      selected: [] as IAssociate[],
      headers: [
        { text: "USERS", value: "user" },
        { text: "CONTACT INFO", value: "user_name" },
        { text: "CREATED ON", value: "created" },
        // { text: "LAST LOGIN", value: "action" },
        { text: "", value: "action" },
      ],
      getYear: getYear,
      getDate: getDate,
    };
  },
  computed: {
    groupId: function (): number {
      return Number(this.$route.params.groupId);
    },
    users: function (): IAssociate[] {
      return this.$store.state.company.associates;
    },
    items: function (): IAssociate[] {
      return this.users?.filter((u) => {
        if(u.status == 2){ // Showing only active Users
          return !u.associate_associates_groups.find(
            (g) => g.associates_group.id === this.groupId
          );
        }
      });
    },
    _dialog: {
      get: function (): boolean {
        return this.dialog;
      },
      set: function (val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  methods: {
    view(id: number) {
      this.$router.push(this.$route.path + `/${id}`);
    },
    async addMembers() {
      if (!this.selected.length) {
        return
      }
      const fns: any = [];
      this.selected.forEach((user) => {
        const associate_groups = user.associate_associates_groups.map(
          (g) => g.associates_group.id
        );
        associate_groups.push(this.groupId);
        fns.push(associates.updateAssociatedGroup(user.id, { associate_groups }));
      });

      try {
        await Promise.all(fns);
        this.$store.dispatch(Actions.GetCompanyAssociates);
        this.close()
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        this.selected = [];
      }
    },
    close() {
      this.$emit("update:dialog", false)
    }
  },
});
