
import {
  associates,
  IAssociate,
  IAssociateAssociatesGroup,
  IGroup,
} from "@/api";
import { getDate, getYear } from "@/utils";
import Vue from "vue";
import UserCard from "@/components/groups/users/UserCard.vue";
import { permissions } from "@/mixins";
import AddMemebersModal from "./AddMemebersModal.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import { Actions } from "@/store/models";
import CompanyHeader from "@/components/common/gssiAdmin/CompanyHeader.vue";
import EditableLabel from "@/components/common/EditableLabel.vue";

export default Vue.extend({
  name: "Users",
  components: {
    UserCard,
    AddMemebersModal,
    ConfirmationDialog,
    CompanyHeader,
    EditableLabel,
  },
  mixins: [permissions],
  data() {
    return {
      headers: [
        { text: "USER", value: "name" },
        { text: "USERNAME", value: "username" },
        { text: "COMPANY", value: "company" },
        { text: "ROLE", value: "role" },
        { text: "LAST UPDATED", value: "dateEdited" },
        { text: "EDITED BY", value: "editedBy" },
      ],
      searchQuery: "",
      newUserDialog: false,
      deleteUserDialog: false,
      disableDelete: true,
    };
  },
  computed: {
    groupId: function (): number {
      return Number(this.$route.params.groupId);
    },
    groupName: function (): string {
      return (
        this.$store.state.company?.groups?.find(
          (g: IGroup) => g.id === this.groupId
        )?.name || ""
      );
    },
    users: function () {
      const users = [] as any;
      const allUsers = this.$store.state.company.associates || [];

      allUsers.forEach((a: IAssociate) => {
        const belongs = a.associate_associates_groups?.find(
          (g: IAssociateAssociatesGroup) => {
            return g.associates_group.id === this.groupId;
          }
        );
        if (belongs) {
          users.push({
            ...a,
            name: `${a.first_name} ${a.last_name}`,
            username: a.user_name,
            company: a.company.name,
            role: a.associate_type.name,
            dateEdited: getDate(a.updated_datetime || a.created_datetime),
            created: getYear(a.created_datetime),
            editedBy: a.updated_by || a.created_by,
            id: a.id,
            status: a.status,
            associate_associates_groups: a.associate_associates_groups,
            profile_pic: a.profile_pic,
          });
        }
      });
      return users;
    },
    filteredUsers: function () {
      const vm = this as any;
      return vm.users.filter((user: any) => {
        return (
          user.name.includes(vm.searchQuery) ||
          user.username.includes(vm.searchQuery)
        );
      });
    },
  },
  beforeCreate() {
    this.$store.dispatch(Actions.GetCompanyAssociates);
  },
  methods: {
    async updateGroupName(name: string) {
      try {
        await associates.updateGroup(this.groupId, { name });
        await this.$store.dispatch(Actions.GetCompanyGroups);
        await this.$store.dispatch(Actions.GetCompanyAssociates)
      } catch (error) {
        this.$store.dispatch(Actions.DisplayError, error);
      }
    },
    toggleNewUserDialog(dialog: boolean) {
      this.newUserDialog = dialog;
    },
    getUserRef(id: number) {
      return `u-${id}`;
    },
    isLastUser(): boolean {
      let count = 0;
      const refs: any = this.$refs;
      this.filteredUsers.forEach((u: any) => {
        const id = this.getUserRef(u.id);
        const ele = refs[id][0];
        if (ele.selected) {
          count++;
        }
      });
      return count === this.users.length;
    },
    deleteUsers() {
      if (this.isLastUser()) {
        this.deleteUserDialog = true;
      } else {
        this.performDelete();
      }
    },
    async performDelete() {
      const refs: any = this.$refs;
      this.filteredUsers.forEach((u: any) => {
        const id = this.getUserRef(u.id);
        const ele = refs[id][0];
        if (ele.selected) {
          ele.deleteUser(this.groupId);
        }
      });

      if (this.isLastUser()) {
        try {
          await associates.deleteGroup(this.groupId);
          await this.$store.dispatch(Actions.GetCompanyGroups);
        } catch (error) {
          this.$store.dispatch(Actions.DisplayError, error);
        }
      }
      this.disableDelete = true;
    },
    selectionUpdated() {
      const refs: any = this.$refs;
      let selected = false;
      this.filteredUsers.forEach((u: any) => {
        const id = this.getUserRef(u.id);
        const ele = refs[id][0];
        if (ele.selected) {
          selected = true;
        }
      });
      this.disableDelete = !selected;
    },
  },
  watch: {
    users() {
      if (this.users.length === 0) {
        this.$router.go(-1);
      }
    },
  },
});
